import { DataSource } from './services/api/apiTypes'
import { SortParams } from './utils/sortUtil'
import { SORT_ORDER } from './interfaces'
import { User } from './features/dsr/requestDetails/requestDetailsSlice'
import { EMAIL_REGEX_RFC2822, UserRoles } from './constants'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { isEqual } from 'underscore'
import { AvatarProps } from '@lightbeamai/design-system'

export const avatarColors: AvatarProps['color'][] = [
  'green',
  'indigo',
  'light-green',
  'blue',
  'magenta',
  'pink',
  'yellow',
  'teal'
]

export const capitalize = (str: string): string => {
  return str.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase()
  })
}

export const getWindowProps = (windowSize: {
  width: number
  height: number
}): {
  isMobileView: boolean
  isDesktopView: boolean
  isCompactView: boolean
  isLargeScreen: boolean
} => {
  return {
    isLargeScreen: windowSize.width >= 1440,
    isDesktopView: windowSize.width >= 1200,
    isMobileView: windowSize.width < 768,
    isCompactView: windowSize.width < 1200
  }
}
export const distinctArrayOfObjects = (
  array: Array<{ [key: string]: string }>,
  matchKey: string
) => {
  const result: { [key: string]: string }[] = []
  const map = new Map()
  for (const item of array) {
    if (!map.has(item[matchKey])) {
      map.set(item[matchKey], true) // set any value to Map
      result.push(item)
    }
  }
  return result
}
export const sortFields = (list: Array<DataSource>, sort: SortParams): Array<DataSource> => {
  if (sort.column !== 'lastModifiedTime') {
    return list.slice().sort((dsA: DataSource, dsB: DataSource) => {
      if (sort.direction === SORT_ORDER.ASC) {
        if (dsA[sort.column] && dsB[sort.column]) {
          if (dsA[sort.column].toLowerCase() < dsB[sort.column].toLowerCase()) return 1
          else if (dsA[sort.column].toLowerCase() > dsB[sort.column].toLowerCase()) return -1

          return 0
        } else {
          if (!dsA[sort.column] && !dsB[sort.column]) return 0
          else if (!dsA[sort.column]) {
            if ('' < dsB[sort.column].toLowerCase()) return 1
            else if ('' > dsB[sort.column].toLowerCase()) return -1

            return 0
          } else {
            if (dsA[sort.column].toLowerCase() < '') return 1
            else if (dsA[sort.column].toLowerCase() > '') return -1

            return 0
          }
        }
      } else {
        if (dsA[sort.column] && dsB[sort.column]) {
          if (dsA[sort.column].toLowerCase() > dsB[sort.column].toLowerCase()) return 1
          else if (dsA[sort.column].toLowerCase() < dsB[sort.column].toLowerCase()) return -1

          return 0
        } else {
          if (!dsA[sort.column] && !dsB[sort.column]) {
            return 0
          } else if (!dsA[sort.column]) {
            if ('' > dsB[sort.column].toLowerCase()) return 1
            else if ('' < dsB[sort.column].toLowerCase()) return -1

            return 0
          } else {
            if (dsA[sort.column].toLowerCase() > '') return 1
            else if (dsA[sort.column].toLowerCase() < '') return -1

            return 0
          }
        }
      }
    })
  } else {
    return list.slice().sort((dsA: DataSource, dsB: DataSource) => {
      if (sort.direction === SORT_ORDER.ASC) {
        if (dsA[sort.column] && dsB[sort.column]) {
          if (dayjs(dsA[sort.column]).isBefore(dsB[sort.column])) return 1
          else if (dayjs(dsA[sort.column]).isAfter(dsB[sort.column])) return -1

          return 0
        } else {
          if (!dsA[sort.column] && !dsB[sort.column]) return 0
          else if (!dsA[sort.column]) return 1
          else return -1
        }
      } else {
        if (dsA[sort.column] && dsB[sort.column]) {
          if (dayjs(dsA[sort.column]).isAfter(dsB[sort.column])) return 1
          else if (dayjs(dsA[sort.column]).isBefore(dsB[sort.column])) return -1

          return 0
        } else {
          if (!dsA[sort.column] && !dsB[sort.column]) return 0
          else if (!dsA[sort.column]) return -1
          else return 1
        }
      }
    })
  }
}

export const getUserName = (user?: User) => {
  let name = user?.firstName || ''
  if (user?.lastName) {
    name += ` ${user.lastName}`
  }
  return name
}

export const postponeOutput = async <T>(data: T, time: number): Promise<T> => {
  return await new Promise((res) => {
    setTimeout(() => res(data), time)
  })
}

export function removeDuplicates<T>(array: T[], key?: string): T[] {
  const seen = new Set()
  return array.filter((item) => {
    const value = key ? item[key] : item
    if (seen.has(value)) {
      return false
    } else {
      seen.add(value)
      return true
    }
  })
}

export const arrayMerge = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a] // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c
}

export function objectId() {
  return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
}

function hex(value) {
  return Math.floor(value).toString(16)
}

export const isAdmin = (roles) => !!roles?.find(({ name }) => name === UserRoles.admin)

export const isDPO = (roles) => !!roles?.find(({ name }) => name === UserRoles.dpo)

export const isDSO = (roles) => !!roles?.find(({ name }) => name === UserRoles.dso)

export const isBase64 = (str: string) => {
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

export const validateDomain = (value) => {
  // Regular expression to validate allowed domain patterns
  const domainPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
      '((www\\.)?' + // www (optional)
      '[a-zA-Z0-9-]+\\.' + // domain name
      '[a-zA-Z]{2,})$', // domain extension
    'i' // case insensitive
  )

  return !!domainPattern.test(value)
}

export const commaSeparatedFormmatting = (list, i) => {
  return i ? (i === list.length - 1 ? '\u00A0and\u00A0' : ',\u00A0') : ''
}

export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX_RFC2822.test(email)
}

export function createPatcher<T>(setter: Dispatch<SetStateAction<T>>) {
  return (fields: Partial<T>) => {
    setter((prev) => ({
      ...prev,
      ...fields
    }))
  }
}

type Item = {
  [key: string]: any // other properties
}

export function removeDuplicatesWithKeys(items: Item[], key1: string, key2: string): Item[] {
  const seen = new Map<string, boolean>()

  return items.filter((item) => {
    const uniqueKey = `${item[key1]}|${item[key2]}`
    if (seen.has(uniqueKey)) {
      return false
    }
    seen.set(uniqueKey, true)
    return true
  })
}

export function isDuplicate<T>(array: T[], value: T) {
  return array.filter((item) => item === value).length > 1
}

export function hasDuplicates<T>(array: T[]) {
  const seen = new Set<T>()
  for (const item of array) {
    if (seen.has(item)) {
      return true // Duplicate found
    }
    seen.add(item)
  }
  return false // No duplicates
}

export function removeFalsyValues(obj: Record<string, any>): Partial<Record<string, any>> {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => Boolean(value)))
}

export const haveSameObjects = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false

  return arr1.every((item) => {
    const found = arr2.find((arr2Item) => isEqual(item, arr2Item))
    return found !== undefined
  })
}

export const deepClone = (obj) => typeof obj === 'object' && JSON.parse(JSON.stringify(obj))
// Utility function to generate colors based on text
export const generateAvatarColors = (text: string) => {
  if (!text || typeof text !== 'string') {
    return 'indigo' // Default neutral colors
  }
  const index = text.trim().toUpperCase().charCodeAt(0) % avatarColors.length
  return avatarColors[index]
}

export function isNotNullOrUndefinedOrEmpty(value): boolean {
  return value !== null && value !== undefined && value !== ''
}

export function approximatedValue(value: string) {
  return `~ ${value}`
}

export function createFieldGetter<T, K extends keyof T>(key: K): (val: T) => T[K] {
  return (val: T) => val[key]
}
