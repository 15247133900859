import {
  DATA_SOURCE_TYPES,
  OBJECT_ARCHIVE_SUPPORTED_DATASOURCES,
  OBJECT_DELETE_SUPPORTED_DATASOURCES
} from '../../../../constants'
import { TagsProps } from '@lightbeamai/design-system'

export enum SubAlertActionState {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  ReviewCompleted = 'REVIEW_COMPLETED',
  OnHold = 'ON_HOLD',
  DetectionConfirmed = 'DETECTION_CONFIRMED',
  IncorrectDetection = 'INCORRECT_DETECTION'
}

export const SubAlertActionStateFilter = {
  [SubAlertActionState.Open]: 'Open',
  [SubAlertActionState.InProgress]: 'InProgress',
  [SubAlertActionState.InReview]: 'InReview',
  [SubAlertActionState.ReviewCompleted]: 'ReviewCompleted',
  [SubAlertActionState.OnHold]: 'OnHold',
  [SubAlertActionState.DetectionConfirmed]: 'DetectionConfirmed',
  [SubAlertActionState.IncorrectDetection]: 'IncorrectDetection'
}

export const SubAlertActionStateConfig: {
  [key in SubAlertActionState]: {
    color: TagsProps['color']
    intlId: string
  }
} = {
  [SubAlertActionState.Open]: { color: 'information', intlId: 'alerts.subalert.state.open' },
  [SubAlertActionState.InProgress]: { color: 'cyan', intlId: 'alerts.subalert.state.inProgress' },
  [SubAlertActionState.InReview]: { color: 'cyan', intlId: 'alerts.subalert.state.inReview' },
  [SubAlertActionState.ReviewCompleted]: {
    color: 'success',
    intlId: 'alerts.subalert.state.reviewCompleted'
  },
  [SubAlertActionState.OnHold]: { color: 'error', intlId: 'alerts.subalert.state.onHold' },
  [SubAlertActionState.DetectionConfirmed]: {
    color: 'information',
    intlId: 'alerts.subalert.state.detectionConfirmed'
  },
  [SubAlertActionState.IncorrectDetection]: {
    color: 'error',
    intlId: 'alerts.subalert.state.incorrectDetection'
  }
}

export function isArchivalSupported(datasourceType?: DATA_SOURCE_TYPES) {
  return OBJECT_ARCHIVE_SUPPORTED_DATASOURCES.includes(datasourceType as DATA_SOURCE_TYPES)
}

export const isObjectDeleteSupported = (datasourceType?: DATA_SOURCE_TYPES) => {
  return OBJECT_DELETE_SUPPORTED_DATASOURCES.includes(datasourceType as DATA_SOURCE_TYPES)
}

export enum ActionsTakeBy {
  user = 'User',
  system = 'System'
}
