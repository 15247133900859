import { capitalizeFirstLetter } from './stringUtil'
import { SCAN_FREQUENCY_RUN_ON_PRESETS, SENSITIVE_LABEL } from '../constants'
import { capitalize, haveSameObjects } from '../utils'
import { Classification } from '../services/api/apiTypes'

export enum RulesetType {
  classification = 'FILE_CLASSIFICATION_SET',
  attributeSensitivity = 'ATTRIBUTE_SENSITIVITY_COUNT',
  attributeInstanceCount = 'ATTRIBUTE_INSTANCE_COUNT',
  label = 'DOCUMENT_LABELS',
  riskScore = 'RISK_SCORE'
}

export enum CriteriaOperatorTypes {
  or = 'OR',
  and = 'AND'
}
export enum CriteriaGroupOperatorTypes {
  or = 'ANY_OF',
  and = 'ALL_OF',
  nor = 'NOT_ANY_OF',
  nand = 'NOT_ALL_OF'
}

const OPERATOR_CONFIG = {
  [RulesetType.classification]: [CriteriaGroupOperatorTypes.or, CriteriaGroupOperatorTypes.nor]
}

export const getGroupOperatorOptions = (intl, type?: RulesetType) => {
  const options = [
    {
      key: CriteriaGroupOperatorTypes.or,
      value: CriteriaGroupOperatorTypes.or,
      label: intl.formatMessage({ id: 'labels.conditionOr' })
    },
    {
      key: CriteriaGroupOperatorTypes.and,
      value: CriteriaGroupOperatorTypes.and,
      label: intl.formatMessage({ id: 'labels.conditionAnd' })
    },
    {
      key: CriteriaGroupOperatorTypes.nor,
      value: CriteriaGroupOperatorTypes.nor,
      label: intl.formatMessage({ id: 'labels.conditionNor' })
    },
    {
      key: CriteriaGroupOperatorTypes.nand,
      value: CriteriaGroupOperatorTypes.nand,
      label: intl.formatMessage({ id: 'labels.conditionNand' })
    }
  ]
  const operatorConfig = type && OPERATOR_CONFIG[type]
  if (!operatorConfig) return options
  return options.filter((option) => operatorConfig.includes(option.key))
}

export const getCriteriaOptions = (intl) => [
  {
    key: CriteriaOperatorTypes.or,
    value: CriteriaOperatorTypes.or,
    label: intl.formatMessage({ id: 'labels.any' })
  },
  {
    key: CriteriaOperatorTypes.and,
    value: CriteriaOperatorTypes.and,
    label: intl.formatMessage({ id: 'labels.all' })
  }
]

export const getLabelOption = (intl) => ({
  key: RulesetType.label,
  value: RulesetType.label,
  label: intl.formatMessage({ id: 'ruleset.field.criteria.DOCUMENT_LABELS' })
})

export const getRulesetTypeOptions = (intl) => [
  {
    key: RulesetType.classification,
    value: RulesetType.classification,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.FILE_CLASSIFICATION_SET' })
  },
  {
    key: RulesetType.attributeInstanceCount,
    value: RulesetType.attributeInstanceCount,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.ATTRIBUTE_INSTANCE_COUNT' })
  },
  {
    key: RulesetType.attributeSensitivity,
    value: RulesetType.attributeSensitivity,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.ATTRIBUTE_SENSITIVITY_COUNT' })
  },
  {
    key: RulesetType.riskScore,
    value: RulesetType.riskScore,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.RISK_SCORE' })
  },
  getLabelOption(intl)
]

export type AttributeSensitivityType = {
  type: RulesetType.attributeSensitivity
  attributeSensitivityCounts: {
    attributeSensitivityLabel: SENSITIVE_LABEL
    minCount: number
  }[]
}

export type RiskScoreType = {
  type: RulesetType.riskScore
  riskScore: number
}

export type AttributeInstanceCountType = {
  type: RulesetType.attributeInstanceCount
  attributeInstanceCounts: {
    attributeId: string
    minCount: number
    maxCount: number | null
  }[]
}

export type FileClassificationType = {
  type: RulesetType.classification
  fileClassificationSet: {
    fileClass: string
    fileSubClass: string
  }[]
}

export type LabelType = {
  type: RulesetType.label
  documentLabels: {
    labelSetId: string
    labelId: string
  }[]
}

export type Ruleset = {
  operator: CriteriaGroupOperatorTypes
  collasped?: boolean
} & (
  | AttributeSensitivityType
  | AttributeInstanceCountType
  | FileClassificationType
  | LabelType
  | RiskScoreType
)

export const defaultClassificationRuleset: Ruleset = {
  type: RulesetType.classification,
  operator: CriteriaGroupOperatorTypes.or,
  fileClassificationSet: [],
  collasped: false
}

export const defaultAttributeInstanceCountRuleset: Ruleset = {
  type: RulesetType.attributeInstanceCount,
  operator: CriteriaGroupOperatorTypes.or,
  attributeInstanceCounts: [],
  collasped: false
}

type SelectedClassification = {
  fileClass: string
  fileSubClass: string
}

type SelectedLabelSet = {
  labelSetId: string
  labelId: string
}
export const mapClassificationsObjectToArray = (checkedClassifications) => {
  return Object.keys(checkedClassifications).reduce((acc: SelectedClassification[], key) => {
    const subclasses = checkedClassifications[key]
    if (subclasses.length) {
      subclasses.forEach((subclass) => {
        acc.push({
          fileClass: key,
          fileSubClass: subclass
        })
      })
    }
    return acc
  }, [])
}

export const mapClassificationsArrayToObject = (
  checkedGroups: SelectedClassification[],
  classifications?: Classification[]
) => {
  if (classifications?.length) {
    return checkedGroups?.reduce((acc, group) => {
      const { fileClass, fileSubClass } = group
      // entire class is selected
      if (fileSubClass === null) {
        const subClasses =
          classifications.find((classification) => classification.class === fileClass)
            ?.subclasses || []
        acc[fileClass] = subClasses
      } else {
        if (acc[fileClass]) {
          acc[fileClass].push(fileSubClass)
        } else {
          acc[fileClass] = [fileSubClass]
        }
      }
      return acc
    }, {})
  }
  return {}
}

export const mapLabelSetsObjectToArray = (checkedLabelSets) => {
  return Object.keys(checkedLabelSets).reduce((acc: SelectedLabelSet[], key) => {
    const labels = checkedLabelSets[key]
    if (labels.length) {
      labels.forEach((label) => {
        acc.push({
          labelSetId: key,
          labelId: label
        })
      })
    }
    return acc
  }, [])
}

export const mapLabelSetsArrayToObject = (checkedGroups: SelectedLabelSet[]) => {
  return checkedGroups?.reduce((acc, group) => {
    const { labelSetId, labelId } = group
    if (acc[labelSetId]) {
      acc[labelSetId].push(labelId)
    } else {
      acc[labelSetId] = [labelId]
    }
    return acc
  }, {})
}

export const getTagColor = (label) => {
  switch (label) {
    case SENSITIVE_LABEL.HIGH:
      return 'error'
    case SENSITIVE_LABEL.MEDIUM:
      return 'caution'
    case SENSITIVE_LABEL.LOW:
      return 'success'
    default:
      return 'information'
  }
}

export const validateClassificationRuleset = (ruleset: Ruleset) => {
  return ruleset.type === RulesetType.classification && ruleset?.fileClassificationSet?.length > 0
}

export const validateAttributeInstanceCountRuleset = (ruleset: Ruleset) => {
  return (
    ruleset.type === RulesetType.attributeInstanceCount &&
    ruleset?.attributeInstanceCounts?.length > 0
  )
}

export const validateAttributeSensitivityRuleset = (ruleset: Ruleset) => {
  return (
    ruleset.type === RulesetType.attributeSensitivity &&
    ruleset?.attributeSensitivityCounts?.length > 0
  )
}

export const validateLabelRuleset = (ruleset: Ruleset) => {
  return ruleset.type === RulesetType.label && ruleset?.documentLabels?.length > 0
}

export const validateRiskScoreRuleset = (ruleset: Ruleset) => {
  return (
    ruleset.type === RulesetType.riskScore && ruleset?.riskScore >= 0 && ruleset?.riskScore <= 100
  )
}

export const validateRulesets = (rulesets: Ruleset[]) => {
  return rulesets?.every((ruleset) => {
    // "operator" does not exist on risk score type
    const hasMeta =
      ruleset.type && (ruleset.type === RulesetType.riskScore ? true : !!ruleset.operator)
    let hasConfigs = false
    switch (ruleset.type) {
      case RulesetType.classification:
        hasConfigs = validateClassificationRuleset(ruleset)
        break
      case RulesetType.attributeInstanceCount:
        hasConfigs = validateAttributeInstanceCountRuleset(ruleset)
        break
      case RulesetType.attributeSensitivity:
        hasConfigs = validateAttributeSensitivityRuleset(ruleset)
        break
      case RulesetType.label:
        hasConfigs = validateLabelRuleset(ruleset)
        break
      case RulesetType.riskScore:
        hasConfigs = validateRiskScoreRuleset(ruleset)
        break
    }
    return hasMeta && hasConfigs
  })
}

export const hasOnlyDefaultRuleset = (ruleset: Ruleset) => {
  const rule = JSON.parse(JSON.stringify(ruleset))
  // match default ruleset value for collapsed
  rule.collasped = false
  const stringifyRuleset = JSON.stringify(rule)
  return (
    stringifyRuleset === JSON.stringify(defaultClassificationRuleset) ||
    stringifyRuleset === JSON.stringify(defaultAttributeInstanceCountRuleset)
  )
}

export enum AttributeInstanceOptions {
  attributeSensitivity = 'SENSITIVITY',
  attributeSet = 'ATTRIBUTE SET'
}

export const getAttributeInstanceOptions = () => {
  return Object.keys(AttributeInstanceOptions).map((type) => ({
    key: type,
    value: AttributeInstanceOptions[type],
    label: capitalize(AttributeInstanceOptions[type] as string)
  }))
}

export const sensitivityOptions = Object.keys(SENSITIVE_LABEL)

export const sensitivityDropdownOptions = Object.keys(SENSITIVE_LABEL).map((label) => ({
  value: label,
  label: capitalizeFirstLetter(label)
}))

export type ScanFrequencyConfig = {
  scanAsap: boolean
  trigger: {
    interval: number
    unit: 'MONTH' | 'YEAR'
  }
  startFrom: string
  runOn: number | SCAN_FREQUENCY_RUN_ON_PRESETS
}

export const defaultScanFrequency: ScanFrequencyConfig = {
  scanAsap: true,
  trigger: {
    interval: 1,
    unit: 'MONTH'
  },
  startFrom: new Date().toISOString(),
  runOn: 1
}

export const checkIfRulesetsAreUpdated = (initialRulesets: Ruleset[], rulesets: Ruleset[]) => {
  if (initialRulesets.length !== rulesets.length) {
    return true
  }
  const isOperatorOrTypeChanged = initialRulesets.some((initialRuleset, index) => {
    const updatedRuleset = rulesets[index]
    return (
      updatedRuleset?.operator !== initialRuleset.operator ||
      updatedRuleset?.type !== initialRuleset.type
    )
  })

  if (isOperatorOrTypeChanged) {
    return true
  }

  const isSelectionChanged = initialRulesets.some((initialRuleset, index) => {
    const updatedRuleset = rulesets[index]
    if (
      updatedRuleset?.type === RulesetType.classification &&
      initialRuleset?.type === RulesetType.classification
    ) {
      const { fileClassificationSet: initialClassificationSet } = initialRuleset
      const { fileClassificationSet: updatedClassificationSet } = updatedRuleset
      return !haveSameObjects(initialClassificationSet, updatedClassificationSet)
    }
    if (
      updatedRuleset?.type === RulesetType.attributeInstanceCount &&
      initialRuleset?.type === RulesetType.attributeInstanceCount
    ) {
      const { attributeInstanceCounts: initialAttributeInstanceCounts } = initialRuleset
      const { attributeInstanceCounts: updatedAttributeInstanceCounts } = updatedRuleset
      return !haveSameObjects(initialAttributeInstanceCounts, updatedAttributeInstanceCounts)
    }
    if (
      updatedRuleset?.type === RulesetType.attributeSensitivity &&
      initialRuleset?.type === RulesetType.attributeSensitivity
    ) {
      const { attributeSensitivityCounts: initialAttributeSensitivityCounts } = initialRuleset
      const { attributeSensitivityCounts: updatedAttributeSensitivityCounts } = updatedRuleset
      return !haveSameObjects(initialAttributeSensitivityCounts, updatedAttributeSensitivityCounts)
    }
    if (updatedRuleset?.type === RulesetType.label && initialRuleset?.type === RulesetType.label) {
      const { documentLabels: initialDocumentLabels } = initialRuleset
      const { documentLabels: updatedDocumentLabels } = updatedRuleset
      return !haveSameObjects(initialDocumentLabels, updatedDocumentLabels)
    }
    if (
      updatedRuleset?.type === RulesetType.riskScore &&
      initialRuleset?.type === RulesetType.riskScore
    ) {
      return updatedRuleset.riskScore !== initialRuleset.riskScore
    }
    return false
  })

  if (isSelectionChanged) {
    return true
  }
  return false
}
