import {
  AccessControlObject,
  AccessControlObjectCard,
  AccessControlObjectSummary,
  AccessControlGroupsWidget,
  AccessControlIdpWidget,
  AccessControlObjectsWithImpactedAccess,
  AccessControlUserDatasource,
  AccessControlUserDetailsOverview,
  AccessControlUserEntities,
  AccessControlUserGroupByDepartmentItem,
  AccessControlUserListItem,
  AccessControlUsersSummary,
  AccessControlUsersWidget,
  DataSensitivityLevels,
  IGetAccessControlUsersFilterParams,
  ObjectsImpactedAccessTypeFilter,
  AccessControlEntityWidget,
  AccessControlUserEntityCard,
  Access360Widget,
  AccessControlGlobalObjectCard,
  AccessControlEntityAccessWidget,
  AccessControlObjectsWithImpactedAccessByDept,
  AccessControlUserAttributesWidget,
  AccessControlUserAttributeCard,
  AccessControlCrossDepartmentAccessWidget,
  AccessControlAccessibleWidget,
  AccessControlFilters,
  AccessControlObjectCardBySensitivity,
  AccessControlGroupDetailsOverview,
  IGetAccessControlGroupsFilterParams,
  IdentityProviderParams,
  DirectoryService,
  AlertsOverviewWidget,
  TestConnectionParams,
  AccessControlGroupCard
} from './types'
import {
  mapQueryAccessControlObjectsList,
  mapQueryAccessControlObjectsSummary,
  mapQueryGroupsWidget,
  mapQueryIdpWidget,
  mapQueryObjectsWithImpactedAccess,
  mapQueryUserCardsGroupedByDepartments,
  mapQueryUserDatasources,
  mapQueryUserEntities,
  mapQueryUserOverview,
  mapQueryUsers,
  mapQueryUsersForEntities,
  mapQueryUsersSummary,
  queryAccessControlObjectsList,
  queryAccessControlObjectsSummary,
  mapQueryUsersWidget,
  queryGroupsWidget,
  queryIdpWidget,
  queryObjectsWithImpactedAccess,
  queryUserCardsGroupedByDepartments,
  queryUserDatasources,
  queryUserEntities,
  queryUserOverview,
  queryUsers,
  queryUsersSummary,
  queryUsersWidget,
  queryAccessControlObjectsCards,
  mapQueryAccessControlObjectsCards,
  queryEntitiesWithAccess,
  mapQueryUserEntitiesWithAccess,
  mapQueryAccessControlGlobalObjectsSummary,
  queryAccessControlGlobalObjectsSummary,
  mapQueryAccessControlGlobalObjectsCards,
  queryAccessControlGlobalObjectsCards,
  queryAccessControlEntitiesGroupByType,
  mapAccessControlEntitiesGroupByType,
  queryUserEntitiesGroupByDatasource,
  mapAccessControlEntitiesGroupByDatasource,
  queryAccess360Widget,
  mapQueryAccess360Widget,
  queryEntityTypesWithAccess,
  mapQueryEntityTypesWithAccess,
  queryObjectsWithImpactedAccessByDept,
  mapQueryObjectsWithImpactedAccessByDept,
  queryAccessControlUserAttributesSummary,
  mapQueryAccessControlUserAttributesSummary,
  queryAccessControlUserAttributeCards,
  mapQueryAccessControlUserAttributeCards,
  queryObjectsWithCrossDepartmentAccessWithDepartments,
  mapQueryObjectsWithCrossDepartmentAccessWithDepartments,
  queryAccessibleWidget,
  mapQueryAccessibleWidget,
  queryAccessControlFilters,
  mapQueryAccessControlFilters,
  queryAccessControlObjectsCardBySensitivity,
  mapQueryAccessControlObjectsCardsBySensitivity,
  queryAccessControlCounts,
  mapQueryAccessControlCounts,
  queryAccessControlObjectDetails,
  mapQueryAccessControlObjectDetails,
  queryGroups,
  mapQueryGroups,
  queryAccessControlIdpDatasources,
  mapQueryAccessControlIdpDatasources,
  mutationCreateIdentityProvider,
  queryAccessControlIdpList,
  mapQueryAccessControlIdpList,
  queryAccessControlIdpById,
  mapQueryAccessControlIdpById,
  mutationUpdateIdentityProvider,
  mapQueryGroupsForEntities,
  queryGroupOverview,
  mapQueryGroupOverview,
  queryUsersByGroup,
  mapQueryUsersByGroup,
  mapQuerySubGroups,
  querySubGroups,
  queryGroupsByUser,
  mapQueryGroupsByUser,
  queryAlertsOverviewWidget,
  mapQueryAlertsOverviewWidget,
  mutationTestConnection,
  mapQueryUsersForObjects,
  queryAccessControlTotalObjectPerUserOrGroup,
  mapQueryAccessControlObjectsTotalObjects,
  queryAccessControlObjectsAccessViaGroup,
  mapQueryAccessControlObjectsAccessViaGroup,
  queryEntitiesViaGroup,
  mapQueryAccessControlEntitiesViaGroup,
  queryAttributesViaGroup,
  mapQueryAttributesViaGroup
} from './queries'
import graphqlService from '../../services/graphqlService'
import { PageInfo } from '../../services/graphqlSchemaTypes'
import { PAGE } from '../../constants'
import { DataSource } from '../../services/api/apiTypes'
import { DownloadListParams } from '../../interfaces'
import apiService from '../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ACTION_FETCH_USERS_CARDS_BY_DEPT = 'accessControl/users/cardsByDept'
export const fetchUsersCardsGroupedByDept = createAsyncThunk(
  ACTION_FETCH_USERS_CARDS_BY_DEPT,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryUserCardsGroupedByDepartments(params as IGetAccessControlUsersFilterParams)
    )
    return mapQueryUserCardsGroupedByDepartments(resultRaw)
  }
)

export const ACTION_FETCH_USERS_LIST = 'accessControl/users/list'
export const fetchUsersList = createAsyncThunk(
  ACTION_FETCH_USERS_LIST,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryUsers(params as IGetAccessControlUsersFilterParams)
    )

    return params.entityId
      ? mapQueryUsersForEntities(resultRaw)
      : params.objectIds?.length
      ? mapQueryUsersForObjects(resultRaw)
      : mapQueryUsers(resultRaw)
  }
)

export const ACTION_FETCH_USERS_BY_GROUP = 'accessControl/groups/users/list'
export const fetchUsersByGroup = createAsyncThunk(
  ACTION_FETCH_USERS_BY_GROUP,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryUsersByGroup(params as IGetAccessControlUsersFilterParams)
    )

    return mapQueryUsersByGroup(resultRaw)
  }
)

export const ACTION_FETCH_GROUPS_LIST = 'accessControl/groups/list'
export const fetchGroupsList = createAsyncThunk(
  ACTION_FETCH_GROUPS_LIST,
  async (params: IGetAccessControlGroupsFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryGroups(params as IGetAccessControlGroupsFilterParams)
    )

    return params.entityId ? mapQueryGroupsForEntities(resultRaw) : mapQueryGroups(resultRaw)
  }
)

export const ACTION_FETCH_GROUPS_BY_USER = 'accessControl/users/groups/list'
export const fetchGroupsByUser = createAsyncThunk(
  ACTION_FETCH_GROUPS_BY_USER,
  async (params: IGetAccessControlGroupsFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryGroupsByUser(params as IGetAccessControlGroupsFilterParams)
    )

    return mapQueryGroupsByUser(resultRaw)
  }
)

export const ACTION_FETCH_SUB_GROUPS = 'accessControl/subgroups'
export const fetchSubGroups = createAsyncThunk(
  ACTION_FETCH_SUB_GROUPS,
  async (params: IGetAccessControlGroupsFilterParams) => {
    const resultRaw = await graphqlService.execute(
      querySubGroups(params as IGetAccessControlGroupsFilterParams)
    )

    return mapQuerySubGroups(resultRaw)
  }
)

export const ACTION_FETCH_USERS_SUMMARY = 'accessControl/users/summary'
export const fetchUsersSummary = createAsyncThunk(
  ACTION_FETCH_USERS_SUMMARY,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryUsersSummary(params))
    return mapQueryUsersSummary(resultRaw)
  }
)

export const ACTION_FETCH_USER_OVERVIEW = 'accessControl/user/overview'
export const fetchUserOverview = createAsyncThunk(
  ACTION_FETCH_USER_OVERVIEW,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryUserOverview(params))
    return mapQueryUserOverview(resultRaw)
  }
)

export const ACTION_FETCH_GROUP_OVERVIEW = 'accessControl/group/overview'
export const fetchGroupOverview = createAsyncThunk(
  ACTION_FETCH_GROUP_OVERVIEW,
  async (params: IGetAccessControlGroupsFilterParams) => {
    const resultRaw = await graphqlService.execute(queryGroupOverview(params))
    return mapQueryGroupOverview(resultRaw)
  }
)

export const ACTION_FETCH_USER_DATASOURCES = 'accessControl/user/datasources'
export const fetchUserDatasources = createAsyncThunk(
  ACTION_FETCH_USER_DATASOURCES,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryUserDatasources(params))
    const dataSourcesResult = mapQueryUserDatasources(resultRaw)
    return dataSourcesResult
  }
)

export const ACTION_FETCH_ACCESS_CONTROL_FILTERS = 'accessControl/filters'
export const fetchAccessControlFilters = createAsyncThunk(
  ACTION_FETCH_ACCESS_CONTROL_FILTERS,
  async () => {
    const resultRaw = await graphqlService.execute(queryAccessControlFilters())
    return mapQueryAccessControlFilters(resultRaw)
  }
)

export const ACTION_FETCH_ACCESS_CONTROL_IDP_DATASOURCES = 'accessControl/idp/datasources'
export const fetchAccessControlIdpDatasources = createAsyncThunk(
  ACTION_FETCH_ACCESS_CONTROL_IDP_DATASOURCES,
  async () => {
    const resultRaw = await graphqlService.execute(queryAccessControlIdpDatasources())
    return mapQueryAccessControlIdpDatasources(resultRaw)
  }
)

export const ACTION_CREATE_IDENTITY_PROVIDER = 'accessControl/idp/create'
export const createIdentityProvider = createAsyncThunk(
  ACTION_CREATE_IDENTITY_PROVIDER,
  async (params: IdentityProviderParams) => {
    await graphqlService.execute(mutationCreateIdentityProvider(params))
    // return mapQueryCreateIdentityProvider(resultRaw)
  }
)

export const ACTION_GOVERNANCE_REPORT = 'accessControl/governanceReport'
export const fetchReport = createAsyncThunk(
  ACTION_GOVERNANCE_REPORT,
  async (params: DownloadListParams): Promise<{ data: string; fileName: string }> => {
    const { data, headers } = await apiService.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

// User Attributes
export type AccessControlUserAttributesParams = {
  userId?: number
  groupId?: number
  datasourceIds?: string[]
  sensitivity?: DataSensitivityLevels[]
  attributeIds?: string[]
  [PAGE]?: number
}
export const ACTION_FETCH_USER_ATTRIBUTES_SUMMARY = 'accessControl/user/attributes/summary'
export const fetchUserAttributesSummary = createAsyncThunk(
  ACTION_FETCH_USER_ATTRIBUTES_SUMMARY,
  async (params: AccessControlUserAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlUserAttributesSummary(params))
    return mapQueryAccessControlUserAttributesSummary(resultRaw)
  }
)
export const ACTION_FETCH_USER_ATTRIBUTES_CARDS = 'accessControl/user/attributes/cards'
export const fetchUserAttributeCards = createAsyncThunk(
  ACTION_FETCH_USER_ATTRIBUTES_CARDS,
  async (params: AccessControlUserAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlUserAttributeCards(params))
    return mapQueryAccessControlUserAttributeCards(resultRaw)
  }
)

// 360 access control: objects
export type AccessControlObjectsParams = {
  hasOpenAccess?: boolean
  hasExcessiveAccess?: boolean
  hasCrossDepartmentsAccess?: boolean
  hasDirectAccess?: boolean
  datasourceIds?: string[]
  sensitivity?: DataSensitivityLevels[]
  attributeIds?: string[]
  accessControlUserIds?: number[]
  accessControlGroupIds?: number[]
  isOwner?: boolean
  departmentName?: string
  [PAGE]?: number
  fetchCount?: boolean
  searchText?: string
}
export const ACTION_OBJECTS_SUMMARY = 'accessControl/objects/summary'
export const fetchObjectsSummary = createAsyncThunk(
  ACTION_OBJECTS_SUMMARY,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlObjectsSummary(params))
    return mapQueryAccessControlObjectsSummary(resultRaw)
  }
)

export const ACTION_OBJECTS_SUMMARY_FILTERED = 'accessControl/objects/summaryFiltered'
export const fetchObjectsCounts = createAsyncThunk(
  ACTION_OBJECTS_SUMMARY_FILTERED,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlCounts(params))
    return mapQueryAccessControlCounts(resultRaw)
  }
)

export const ACTION_OBJECTS_CARDS = 'accessControl/objects/cards'
export const fetchObjectsCards = createAsyncThunk(
  ACTION_OBJECTS_CARDS,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlObjectsCards(params))
    return mapQueryAccessControlObjectsCards(resultRaw)
  }
)

export const ACTION_OBJECTS_CARDS_BY_SENSITIVITY = 'accessControl/objects/cardsBySensitivity'
export const fetchObjectsCardsBySensitivity = createAsyncThunk(
  ACTION_OBJECTS_CARDS_BY_SENSITIVITY,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(
      queryAccessControlObjectsCardBySensitivity(params)
    )
    return mapQueryAccessControlObjectsCardsBySensitivity(resultRaw)
  }
)

export const ACTION_OBJECTS_TOTAL_OBJECTS_FOR_USER_OR_GROUP =
  'accessControl/objects/totalObjectsForUserOrGroup'
export const fetchTotalObjectsForUserOrGroup = createAsyncThunk(
  ACTION_OBJECTS_TOTAL_OBJECTS_FOR_USER_OR_GROUP,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(
      queryAccessControlTotalObjectPerUserOrGroup(params)
    )
    return mapQueryAccessControlObjectsTotalObjects(resultRaw)
  }
)

export const ACTION_OBJECTS_ACCESS_VIA_GROUP = 'accessControl/objects/accessViaGroup'
export const fetchObjectsAccessViaGroup = createAsyncThunk(
  ACTION_OBJECTS_ACCESS_VIA_GROUP,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlObjectsAccessViaGroup(params))
    return mapQueryAccessControlObjectsAccessViaGroup(resultRaw)
  }
)

export const ACTION_OBJECTS_LIST = 'accessControl/objects/list'
export const fetchObjectsList = createAsyncThunk(
  ACTION_OBJECTS_LIST,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlObjectsList(params))
    return mapQueryAccessControlObjectsList(resultRaw)
  }
)
export const ACTION_OBJECT_DETAILS = 'accessControl/objectDetails'
export const fetchObjectsDetails = createAsyncThunk(
  ACTION_OBJECT_DETAILS,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlObjectDetails(params))
    return mapQueryAccessControlObjectDetails(resultRaw)
  }
)

export const ACTION_ACCESS_CONTROL_GLOBAL_OBJECTS_SUMMARY = 'accessControl/objects/global-summary'
export const fetchAccessControlGlobalObjectsSummary = createAsyncThunk(
  ACTION_ACCESS_CONTROL_GLOBAL_OBJECTS_SUMMARY,
  async () => {
    const resultRaw = await graphqlService.execute(queryAccessControlGlobalObjectsSummary())
    return mapQueryAccessControlGlobalObjectsSummary(resultRaw)
  }
)

export const ACTION_GLOBAL_OBJECTS_CARDS = 'accessControl/objects/global-cards'
export const fetchGlobalObjectsCards = createAsyncThunk(
  ACTION_GLOBAL_OBJECTS_CARDS,
  async (params: AccessControlObjectsParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlGlobalObjectsCards(params))
    return mapQueryAccessControlGlobalObjectsCards(resultRaw)
  }
)

// User entities
export const ACTION_FETCH_USER_ENTITIES = 'accessControl/user/entities'
export const fetchUserEntities = createAsyncThunk(
  ACTION_FETCH_USER_ENTITIES,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryUserEntities(params))
    return mapQueryUserEntities(resultRaw)
  }
)

export const ACTION_FETCH_USER_ENTITIES_CARD = 'accessControl/user/entitiesCard'
export const fetchUserEntityCards = createAsyncThunk(
  ACTION_FETCH_USER_ENTITIES_CARD,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlEntitiesGroupByType(params))
    const entityCards = mapAccessControlEntitiesGroupByType(resultRaw)
    const groupByDatasources = entityCards.length
      ? await graphqlService.execute(queryUserEntitiesGroupByDatasource(entityCards, params))
      : []

    return mapAccessControlEntitiesGroupByDatasource(groupByDatasources, entityCards)
  }
)

export const ACTION_FETCH_USERS_WIDGET = 'accessControl/dashboard/usersWidget'
export const fetchUsersWidget = createAsyncThunk(
  ACTION_FETCH_USERS_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryUsersWidget(params))
    return mapQueryUsersWidget(resultRaw)
  }
)

export const ACTION_FETCH_GROUPS_WIDGET = 'accessControl/dashboard/groupsWidget'
export const fetchGroupsWidget = createAsyncThunk(
  ACTION_FETCH_GROUPS_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryGroupsWidget(params))
    return mapQueryGroupsWidget(resultRaw)
  }
)

export const ACTION_FETCH_IDP_WIDGET = 'accessControl/dashboard/idpWidget'
export const fetchIdpWidget = createAsyncThunk(ACTION_FETCH_IDP_WIDGET, async () => {
  const resultRaw = await graphqlService.execute(queryIdpWidget())
  return mapQueryIdpWidget(resultRaw)
})

export const ACTION_FETCH_ACCESS360_WIDGET = 'accessControl/dashboard/access360'
export const fetchAccess360Widget = createAsyncThunk(
  ACTION_FETCH_ACCESS360_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccess360Widget(params))
    return mapQueryAccess360Widget(resultRaw)
  }
)

export const ACTION_FETCH_ENTITY_TYPES_ACCESS_WIDGET =
  'accessControl/dashboard/entityTypesWithAccess'
export const fetchEntityTypesAccessWidget = createAsyncThunk(
  ACTION_FETCH_ENTITY_TYPES_ACCESS_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryEntityTypesWithAccess(params))
    return mapQueryEntityTypesWithAccess(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_OPEN_ACCESS = 'accessControl/dashboard/objectsWithOpenAccess'
export const fetchObjectsWithOpenAccess = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_OPEN_ACCESS,
  async (sensitivityLevel: DataSensitivityLevels) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithImpactedAccess(ObjectsImpactedAccessTypeFilter.OpenAccess, sensitivityLevel)
    )
    return mapQueryObjectsWithImpactedAccess(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_EXCESSIVE_ACCESS =
  'accessControl/dashboard/objectsWithExcessiveAccess'
export const fetchObjectsWithExcessiveAccess = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_EXCESSIVE_ACCESS,
  async (sensitivityLevel: DataSensitivityLevels) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithImpactedAccess(
        ObjectsImpactedAccessTypeFilter.ExcessiveAccess,
        sensitivityLevel
      )
    )
    return mapQueryObjectsWithImpactedAccess(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_CROSS_DEPT_ACCESS =
  'accessControl/dashboard/objectsWithCrossDeptAccess'
export const fetchObjectsWithCrossDeptAccess = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_CROSS_DEPT_ACCESS,
  async (sensitivityLevel: DataSensitivityLevels) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithImpactedAccess(
        ObjectsImpactedAccessTypeFilter.CrossDepartmentAccess,
        sensitivityLevel
      )
    )
    return mapQueryObjectsWithImpactedAccess(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_OPEN_ACCESS_BY_DEPT =
  'accessControl/dashboard/objectsWithOpenAccessByDept'
export const fetchObjectsWithOpenAccessByDept = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_OPEN_ACCESS_BY_DEPT,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithImpactedAccessByDept(ObjectsImpactedAccessTypeFilter.OpenAccess, params)
    )
    return mapQueryObjectsWithImpactedAccessByDept(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_EXCESSIVE_ACCESS_BY_DEPT =
  'accessControl/dashboard/objectsWithExcessiveAccessByDept'
export const fetchObjectsWithExcessiveAccessByDept = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_EXCESSIVE_ACCESS_BY_DEPT,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithImpactedAccessByDept(ObjectsImpactedAccessTypeFilter.ExcessiveAccess, params)
    )
    return mapQueryObjectsWithImpactedAccessByDept(resultRaw)
  }
)

export const ACTION_FETCH_OBJECTS_WITH_CROSS_DEPT_ACCESS_BY_DEPT =
  'accessControl/dashboard/objectsWithCrossDeptAccessByDept'
export const fetchObjectsWitCrossDeptAccessByDept = createAsyncThunk(
  ACTION_FETCH_OBJECTS_WITH_CROSS_DEPT_ACCESS_BY_DEPT,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(
      queryObjectsWithCrossDepartmentAccessWithDepartments(params)
    )
    return mapQueryObjectsWithCrossDepartmentAccessWithDepartments(resultRaw)
  }
)

export const ACTION_FETCH_ENTITIES_WIDGET = 'accessControl/dashboard/entitiesWidget'
export const fetchEntitiesWidget = createAsyncThunk(ACTION_FETCH_ENTITIES_WIDGET, async () => {
  const resultRaw = await graphqlService.execute(queryEntitiesWithAccess())
  return mapQueryUserEntitiesWithAccess(resultRaw)
})

export const ACTION_FETCH_ACCESSIBLE_WIDGET = 'accessControl/dashboard/accessibleWidget'
export const fetchAccessibleWidget = createAsyncThunk(
  ACTION_FETCH_ACCESSIBLE_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccessibleWidget(params))
    return mapQueryAccessibleWidget(resultRaw)
  }
)

export const ACTION_FETCH_ALERTS_OVERVIEW_WIDGET = 'accessControl/dashboard/alertsOverviewWidget'
export const fetchAlertsOverviewWidget = createAsyncThunk(
  ACTION_FETCH_ALERTS_OVERVIEW_WIDGET,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAlertsOverviewWidget(params))
    return mapQueryAlertsOverviewWidget(resultRaw)
  }
)

export const ACTION_FETCH_ACCESS_CONTROL_IDP_LIST = 'accessControl/idp/list'
export const fetchAccessControlIdpList = createAsyncThunk(
  ACTION_FETCH_ACCESS_CONTROL_IDP_LIST,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccessControlIdpList(params))
    return mapQueryAccessControlIdpList(resultRaw)
  }
)

export const ACTION_FETCH_ACCESS_CONTROL_IDP_BY_ID = 'accessControl/idp/byId'
export const fetchAccessControlIdpById = createAsyncThunk(
  ACTION_FETCH_ACCESS_CONTROL_IDP_BY_ID,
  async (id: string) => {
    const resultRaw = await graphqlService.execute(queryAccessControlIdpById(id))
    return mapQueryAccessControlIdpById(resultRaw)
  }
)

export const ACTION_UPDATE_IDENTITY_PROVIDER = 'accessControl/idp/update'
export const updateIdentityProvider = createAsyncThunk(
  ACTION_UPDATE_IDENTITY_PROVIDER,
  async (params: IdentityProviderParams) => {
    await graphqlService.execute(mutationUpdateIdentityProvider(params))
  }
)

export const ACTION_TEST_CONNECTION = 'accessControl/idp/testConnection'
export const testIdpConnection = createAsyncThunk(
  ACTION_TEST_CONNECTION,
  async (params: TestConnectionParams) => {
    const response = await graphqlService.execute(mutationTestConnection(params))
    const data =
      response.testConnectionActiveDirectoryIdp || response.testConnectionGoogleWorkspaceIdp

    return {
      success: data.success
    }
  }
)

export const ACTION_FETCH_ENTITIES_VIA_GROUP = 'accessControl/entities/viaGroup'
export const fetchEntitiesViaGroup = createAsyncThunk(
  ACTION_FETCH_ENTITIES_VIA_GROUP,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryEntitiesViaGroup(params))
    return mapQueryAccessControlEntitiesViaGroup(resultRaw)
  }
)

export const ACTION_FETCH_ATTRIBUTES_VIA_GROUP = 'accessControl/attributes/viaGroup'
export const fetchAttributesViaGroup = createAsyncThunk(
  ACTION_FETCH_ATTRIBUTES_VIA_GROUP,
  async (params: IGetAccessControlUsersFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAttributesViaGroup(params))
    return mapQueryAttributesViaGroup(resultRaw)
  }
)

interface AccessControlState {
  usersGroupByDepartments: {
    cards?: AccessControlUserGroupByDepartmentItem[]
    total?: number
  }
  users: {
    list?: AccessControlUserDetailsOverview[]
    total?: number
  }
  groups: {
    list?: AccessControlGroupDetailsOverview[]
    totalList?: number
    totalGroups?: number
    highSensitiveCount?: number
  }
  usersSummary: AccessControlUsersSummary
  userOverview: AccessControlUserDetailsOverview
  groupOverview: AccessControlGroupDetailsOverview
  usersCompact: {
    list?: AccessControlUserListItem[]
    total?: number
  }
  usersDatasources: {
    list?: AccessControlUserDatasource[]
    total?: number
  }
  groupsUsers: {
    list?: AccessControlUserListItem[]
    total?: number
    totalEmployeesCount?: number
    totalContractorsCount?: number
  }
  usersGroups: {
    list?: AccessControlGroupDetailsOverview[]
    total?: number
  }
  subGroups: {
    list?: AccessControlGroupDetailsOverview[]
    total?: number
  }
  objects: {
    list?: AccessControlObject[]
    listTotal?: number
    pageInfo?: PageInfo
    cards?: AccessControlObjectCard[]
    cardsTotal?: number
    globalCards?: AccessControlGlobalObjectCard[]
    globalCardsTotal?: number
    openAccess?: AccessControlObjectSummary
    excessiveAccess?: AccessControlObjectSummary
    crossDepartmentsAccess?: AccessControlObjectSummary
    cardsBySensitivity?: AccessControlObjectCardBySensitivity
    objectsFilteredCount?: number
    userOrGroupsTotalObjects?: number
    ownersFilteredCount?: number
    accessViaGroup?: {
      cards?: AccessControlGroupCard[]
      total?: number
    }
  }
  usersEntities: {
    list?: AccessControlUserEntities[]
    total?: number
    cards?: AccessControlUserEntityCard[]
    cardsTotal?: number
    entitiesAtRisk?: number
    entityTypes?: number
    accessViaGroup?: {
      cards?: AccessControlUserEntityCard[]
      cardsTotal?: number
      listTotal?: number
      list?: AccessControlUserEntities[]
    }
  }
  usersAttributes: {
    cards?: AccessControlUserAttributeCard[]
    cardsTotal?: number
    widgets?: AccessControlUserAttributesWidget[]
    accessViaGroup?: {
      cards?: AccessControlGroupCard[]
      cardsTotal?: number
      widgets?: AccessControlUserAttributesWidget[]
    }
  }

  userEntity: {
    summary?: {
      groupsCount: number
      usersCount: number
    }
  }
  identityProviders: {
    datasources?: DataSource[]
    list?: DirectoryService[]
    total?: number
    selectedIdp?: DirectoryService
    connectionVerified?: boolean
  }
  dashboardWidgets: {
    users?: AccessControlUsersWidget
    groups?: AccessControlGroupsWidget
    idp?: AccessControlIdpWidget
    objectsWithOpenAccess?: AccessControlObjectsWithImpactedAccess
    objectsWithOpenAccessByDept?: AccessControlObjectsWithImpactedAccessByDept
    objectsWithExcessiveAccess?: AccessControlObjectsWithImpactedAccess
    objectsWithCrossDeptAccess?: AccessControlObjectsWithImpactedAccess
    objectsWithExcessiveAccessByDept?: AccessControlObjectsWithImpactedAccessByDept
    objectsWithCrossDeptAccessByDept?: AccessControlCrossDepartmentAccessWidget
    entitiesWithAccess?: AccessControlEntityWidget
    access360?: Access360Widget
    entityTypesWithAccess?: AccessControlEntityAccessWidget
    accessible?: AccessControlAccessibleWidget
    alertsOverview?: AlertsOverviewWidget[]
  }
  filters: AccessControlFilters
  filesToExport?: { data: string; fileName: string }
}
const initialState: AccessControlState = {
  usersGroupByDepartments: {},
  users: {},
  groups: {},
  usersSummary: {
    employeesCount: 0,
    contractorsCount: 0,
    totalContractorsCount: 0,
    totalEmployeesCount: 0
  },
  usersCompact: {},
  userOverview: {
    name: '',
    entitiesCount: 0,
    attributesCount: 0,
    groupsCount: 0
  },
  groupOverview: {
    id: '',
    name: '',
    usersCount: 0,
    attributesCount: 0,
    groupsCount: 0
  },
  groupsUsers: {},
  usersGroups: {},
  subGroups: {},
  usersDatasources: {},
  identityProviders: {},
  usersAttributes: {},
  usersEntities: {},
  userEntity: {},
  objects: {},
  dashboardWidgets: {},
  filters: {}
}

const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    resetUsersCardsGroupedByDept: (state) => {
      state.usersGroupByDepartments = initialState.usersGroupByDepartments
    },
    resetSelectedIdp: (state) => {
      state.identityProviders.selectedIdp = initialState.identityProviders.selectedIdp
    },
    resetEntities: (state) => {
      state.usersEntities = initialState.usersEntities
    },
    resetAttributes: (state) => {
      state.usersAttributes = initialState.usersAttributes
    },
    resetAlertsOverview: (state) => {
      state.dashboardWidgets.alertsOverview = initialState.dashboardWidgets.alertsOverview
    },
    resetFilesToExport: (state) => {
      state.filesToExport = initialState.filesToExport
    },
    resetTestConnection: (state) => {
      state.identityProviders.connectionVerified = initialState.identityProviders.connectionVerified
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersCardsGroupedByDept.fulfilled, (state, { payload }) => {
      state.usersGroupByDepartments.cards = payload.cards
      state.usersGroupByDepartments.total = payload.total
    })
    builder.addCase(fetchUsersCardsGroupedByDept.rejected, (state) => {
      state.usersGroupByDepartments.cards = []
      state.usersGroupByDepartments.total = 0
    })
    builder.addCase(fetchUsersList.fulfilled, (state, { payload }) => {
      state.users.list = payload.list
      state.users.total = payload.total
      state.userEntity.summary = {
        groupsCount: payload?.groupsCount || 0,
        usersCount: payload?.total || 0
      }
    })
    builder.addCase(fetchAccessControlFilters.fulfilled, (state, { payload }) => {
      state.filters.entityTypes = payload.entityTypes
      state.filters.datasources = payload.datasources
      state.filters.departments = payload?.departments || []
      state.filters.attributes = payload.attributes
    })
    builder.addCase(fetchUsersList.rejected, (state) => {
      state.users.list = []
      state.users.total = 0
    })
    builder.addCase(fetchUsersSummary.fulfilled, (state, { payload }) => {
      state.usersSummary = payload
    })
    builder.addCase(fetchUserOverview.fulfilled, (state, { payload }) => {
      state.userOverview = payload
    })
    builder.addCase(fetchGroupOverview.fulfilled, (state, { payload }) => {
      state.groupOverview = payload
    })
    builder.addCase(fetchUserDatasources.fulfilled, (state, { payload }) => {
      state.usersDatasources = payload
    })
    builder.addCase(fetchObjectsSummary.fulfilled, (state, { payload }) => {
      state.objects.openAccess = payload.openAccess
      state.objects.excessiveAccess = payload.excessiveAccess
      state.objects.crossDepartmentsAccess = payload.crossDepartmentsAccess
    })
    builder.addCase(fetchObjectsCounts.fulfilled, (state, { payload }) => {
      state.objects.objectsFilteredCount = payload.objectsCount
      state.objects.ownersFilteredCount = payload.ownersCount
    })
    builder.addCase(fetchAccessControlGlobalObjectsSummary.fulfilled, (state, { payload }) => {
      state.objects.openAccess = payload.openAccess
      state.objects.excessiveAccess = payload.excessiveAccess
      state.objects.crossDepartmentsAccess = payload.crossDepartmentsAccess
    })
    builder.addCase(fetchGlobalObjectsCards.fulfilled, (state, { payload }) => {
      state.objects.globalCards = payload.cards
      state.objects.globalCardsTotal = payload.total
    })
    builder.addCase(fetchObjectsCards.fulfilled, (state, { payload }) => {
      state.objects.cards = payload.cards
      state.objects.cardsTotal = payload.total
    })
    builder.addCase(fetchObjectsCardsBySensitivity.fulfilled, (state, { payload }) => {
      state.objects.cardsBySensitivity = payload
    })
    builder.addCase(fetchTotalObjectsForUserOrGroup.fulfilled, (state, { payload }) => {
      state.objects.userOrGroupsTotalObjects = payload
    })
    builder.addCase(fetchObjectsAccessViaGroup.fulfilled, (state, { payload }) => {
      state.objects.accessViaGroup = payload
    })
    builder.addCase(fetchObjectsList.fulfilled, (state, { payload }) => {
      state.objects.list = payload.list
      state.objects.listTotal = payload.total
      state.objects.pageInfo = payload.pageInfo
    })
    builder.addCase(fetchObjectsDetails.fulfilled, (state, { payload }) => {
      if (state.objects?.list?.length) {
        state.objects.list = [...state.objects.list].map((obj) => {
          return obj.id === payload.id ? { ...obj, ...payload } : obj
        })
      }
    })
    builder.addCase(fetchUserEntityCards.fulfilled, (state, { payload }) => {
      state.usersEntities.cards = payload.cards
      state.usersEntities.cardsTotal = payload.totalCards
      state.usersEntities.entitiesAtRisk = payload.entitiesAtRisk
      state.usersEntities.entityTypes = payload.entityTypes
    })
    builder.addCase(fetchUserEntities.fulfilled, (state, { payload }) => {
      state.usersEntities.list = payload.list
      state.usersEntities.total = payload.total
    })
    builder.addCase(fetchEntitiesViaGroup.fulfilled, (state, { payload }) => {
      state.usersEntities.accessViaGroup = payload
    })
    builder.addCase(fetchUserAttributesSummary.fulfilled, (state, { payload }) => {
      state.usersAttributes.widgets = payload
    })
    builder.addCase(fetchUserAttributeCards.fulfilled, (state, { payload }) => {
      state.usersAttributes.cards = payload.cards
      state.usersAttributes.cardsTotal = payload.total
    })
    builder.addCase(fetchAttributesViaGroup.fulfilled, (state, { payload }) => {
      state.usersAttributes.accessViaGroup = payload
    })
    builder.addCase(fetchObjectsWithOpenAccess.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithOpenAccess = payload
    })
    builder.addCase(fetchObjectsWithExcessiveAccess.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithExcessiveAccess = payload
    })
    builder.addCase(fetchObjectsWithCrossDeptAccess.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithCrossDeptAccess = payload
    })
    builder.addCase(fetchObjectsWithOpenAccessByDept.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithOpenAccessByDept = payload
    })
    builder.addCase(fetchObjectsWithExcessiveAccessByDept.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithExcessiveAccessByDept = payload
    })
    builder.addCase(fetchObjectsWitCrossDeptAccessByDept.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.objectsWithCrossDeptAccessByDept = payload
    })
    builder.addCase(fetchUsersWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.users = payload
    })
    builder.addCase(fetchGroupsWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.groups = payload
    })
    builder.addCase(fetchIdpWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.idp = payload
    })
    builder.addCase(fetchAccess360Widget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.access360 = payload
    })
    builder.addCase(fetchEntityTypesAccessWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.entityTypesWithAccess = payload
    })
    builder.addCase(fetchEntitiesWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.entitiesWithAccess = payload
    })

    builder.addCase(fetchAccessibleWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.accessible = payload
    })
    builder.addCase(fetchAlertsOverviewWidget.fulfilled, (state, { payload }) => {
      state.dashboardWidgets.alertsOverview = payload
    })

    builder.addCase(fetchGroupsList.fulfilled, (state, { payload }) => {
      state.groups.list = payload.list
      state.groups.totalList = payload.total
      state.groups.totalGroups = payload.groupsCount
      state.groups.highSensitiveCount = payload.highSensitiveCount
    })
    builder.addCase(fetchGroupsList.rejected, (state) => {
      state.groups.list = []
      state.groups.totalList = 0
      state.groups.totalGroups = 0
      state.groups.highSensitiveCount = 0
    })
    builder.addCase(fetchUsersByGroup.fulfilled, (state, { payload }) => {
      state.groupsUsers.list = payload.usersList
      state.groupsUsers.total = payload.totalUsers
      state.groupsUsers.totalContractorsCount = payload.totalContractorsCount
      state.groupsUsers.totalEmployeesCount = payload.totalEmployeesCount
    })
    builder.addCase(fetchUsersByGroup.rejected, (state) => {
      state.groupsUsers.list = []
      state.groupsUsers.total = 0
      state.groupsUsers.totalContractorsCount = 0
      state.groupsUsers.totalEmployeesCount = 0
    })
    builder.addCase(fetchGroupsByUser.fulfilled, (state, { payload }) => {
      state.usersGroups.list = payload.groups
      state.usersGroups.total = payload.totalGroups
    })
    builder.addCase(fetchGroupsByUser.rejected, (state) => {
      state.usersGroups.list = []
      state.usersGroups.total = 0
    })
    builder.addCase(fetchSubGroups.fulfilled, (state, { payload }) => {
      state.subGroups.list = payload.list
      state.subGroups.total = payload.total
    })
    builder.addCase(fetchSubGroups.rejected, (state) => {
      state.subGroups.list = []
      state.subGroups.total = 0
    })
    builder.addCase(fetchAccessControlIdpDatasources.fulfilled, (state, { payload }) => {
      state.identityProviders.datasources = payload
    })
    builder.addCase(fetchAccessControlIdpList.fulfilled, (state, { payload }) => {
      state.identityProviders.list = payload.list
      state.identityProviders.total = payload.total
    })
    builder.addCase(fetchAccessControlIdpById.fulfilled, (state, { payload }) => {
      state.identityProviders.selectedIdp = payload
    })
    builder.addCase(testIdpConnection.fulfilled, (state, { payload }) => {
      state.identityProviders.connectionVerified = payload.success
    })
    builder.addCase(testIdpConnection.rejected, (state) => {
      state.identityProviders.connectionVerified = false
    })
    builder.addCase(fetchReport.fulfilled, (state, { payload }) => {
      state.filesToExport = payload
    })
  }
})

export const {
  resetUsersCardsGroupedByDept,
  resetSelectedIdp,
  resetAttributes,
  resetEntities,
  resetFilesToExport,
  resetAlertsOverview,
  resetTestConnection
} = accessControlSlice.actions
export default accessControlSlice.reducer
