import { DirectoryOptions, HostedConfig } from './utils/constants'
import { CronScheduleTypes, DATA_SOURCE_TYPES } from '../../constants'
import { AttributeDataSource } from '../attributes/attributesSlice'
import { DataSource } from '../../services/api/apiTypes'

export type RouteParams = {
  subPage: string
  subSubPage: string
  subPageId?: string
  activeTab?: string
  tabId: string
}

export enum AccessControlUserType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export enum AccessControlEmployeeType {
  Contractor = 'CONTRACTOR',
  Employee = 'EMPLOYEE',
  Unknown = 'UNKNOWN'
}

export enum DataSensitivityLevels {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  None = 'NONE'
}

export enum accessControlMemberTypes {
  Direct = 'DIRECT',
  Inherited = 'INHERITED'
}

export interface AccessControlUserListItem {
  id?: string
  name: string
  userType?: AccessControlUserType
  departmentName?: string
  region?: string
  objectsCount?: number
  datasourcesCount?: number
  entitiesCount?: number
  dataPrivilegeLevel?: DataSensitivityLevels
  email?: string
}

export interface AccessControlGroupListItem {
  id?: string
  name: string
  type?: string
  objectsCount?: number
  datasourcesCount?: number
  entitiesCount?: number
  dataPrivilegeLevel?: DataSensitivityLevels
}

export interface IGetAccessControlUsersFilterParams {
  page?: number
  id?: number
  groupId?: number
  departmentName?: string
  dataPrivilegeLevels?: string[]
  employeeType?: string
  entityId?: string
  attributeId?: string
  datasourceId?: string
  datasourceIds?: string[]
  sensitivityLevel?: DataSensitivityLevels
  sensitivityLevels?: DataSensitivityLevels[]
  entityTypes?: string[]
  risky?: boolean
  attributeIds?: string[]
  objectIds?: string[]
  hasEntityAccess?: boolean
  accessModes?: AccessControlAccessMode[]
  isDirectMembership?: boolean
  emailId?: string
  searchText?: string
}
export interface IGetAccessControlGroupsFilterParams {
  page?: number
  id?: number
  groupId?: number
  dataPrivilegeLevels?: string[]
  entityId?: string
  datasourceId?: string
  entityTypes?: string[]
  hasEntityAccess?: boolean
  employeeType?: string
  isDirect?: boolean
  attributeIds?: string[]
  searchText?: string
}

export type GetAccessControlUserByObjectIdFilterParams = {
  id: string
  pageSize: number
  after?: string
  department?: string
  employeeType?: string[]
  groups?: string[]
  sensitivity?: string[]
}

export interface AccessControlUserGroupByDepartmentItem {
  departmentName?: string
  memberCount?: number
  dataPrivilegeMemberCount?: number
}

export interface AccessControlUsersSummary {
  employeesCount: number
  contractorsCount: number
  totalEmployeesCount: number
  totalContractorsCount: number
}

export interface AccessControlUserDetailsOverview extends AccessControlUserListItem {
  employeeType?: string
  groupsCount: number
  attributesCount: number
  sensitiveObjects?: number
  highSensitiveOwnedObjects?: number
  openAccessObjectsCount?: number
  excessiveAccessObjectsCount?: number
  crossDepartmentAccessObjectsCount?: number
  totalObjectsCount?: number
}

export interface AccessControlGroupDetailsOverview extends AccessControlGroupListItem {
  usersCount: number
  groupsCount: number
  attributesCount: number
  objectsCount?: number
  subGroups?: AccessControlGroupListItem[]
  sensitiveObjects?: number
}

export interface IParamsId {
  id: string
}

export interface AccessControlUserDatasource {
  id: string
  type: DATA_SOURCE_TYPES
  name: string
  dataPrivilegeLevel: DataSensitivityLevels
  entitiesCount: number
  attributesCount: number
  riskDensity?: number
  objectsCount: number
}

export interface AccessControlUserEntities {
  id: number
  type: string
  name: string
  riskStatus: boolean
  datasourcesCount: number
  attributesCount: number
  objectsCount: number
}

export enum AccessControlAccessTypes {
  open = 'Open Access',
  excessive = 'Excessive Access',
  crossDepartments = 'Cross Departments'
}

export enum AccessControlNewAccessTypes {
  direct = 'Direct Access',
  viaGroup = 'Access Via Group'
}

export const AccessControlExtendendAccessTypes = {
  ...AccessControlAccessTypes,
  ...AccessControlNewAccessTypes
}

export type AccessControlObjectSummary = {
  objectsCount?: number
  ownersCount?: number
  dataSourcesCount?: number
}

export type AccessControlObject = {
  id: number
  mongoId?: string

  name: string
  owner?: string
  ownerInfo?: {
    name: string
    email: string
    id: string
  }
  sensitivity?: DataSensitivityLevels
  groupAccess?: Array<{ id: string; name: string }>
  groupAccessCount?: number
  userAccess?: Array<{ id: string; name: string }>
  userAccessCount?: number
  entities?: Array<{ id: string; name: string }>
  entitiesCount?: number
  attributes: Array<{ id: string; name: string; internalName: string }>
  attributesCount?: number
  labels: Array<{ id: string; name: string }>
  isDetailsReceived?: boolean
}
export type AccessControlObjectCard = {
  ownerName?: string
  ownerId?: string
  ownerEmail?: string
  objectsCount?: number
  sensitiveObjectsCount?: number
}

export type AccessControlObjectCardBySensitivity = {
  [DataSensitivityLevels.High]?: number
  [DataSensitivityLevels.Medium]?: number
  [DataSensitivityLevels.Low]?: number
  totalObjects?: number
}

export type AccessControlGlobalObjectCard = {
  dataSourceId: string
  dataSourceName: string
  dataSourceType: DATA_SOURCE_TYPES
  objectsCount: number
  sensitiveObjectsCount: number
  ownersCount: number
}
export interface AccessControlUsersWidget {
  totalUsers: number
  privilegeUsers: number
  employeeUsers: number
  contractorUsers: number
}

export interface AccessControlGroupsWidget {
  totalGroups: number
  privilegeGroups: number
}

export interface AccessControlGroupCard {
  groupId: string
  groupName: string
  objectsCount: number
  attributesCount?: number
}

export enum IdpStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN'
}
export interface IdpProvider {
  id: string
  name: string
  type: string
  status: IdpStatus
}
export interface AccessControlIdpWidget {
  totalProviders: number
  providers: IdpProvider[]
}

export interface AccessControlEntityWidget {
  employeesCount: number
  contractorsCount: number
  employeesUsersCount: number
  contractorsUsersCount: number
  totalEntities: number
}

export interface Access360Widget {
  usersCount: number
  openAccessObjects: number
  crossDepartmentAccessObjects: number
  execessiveAccessObjects: number
  objectsCount: number
  usersWithAccessToEntities: number
}

export enum ObjectsImpactedAccessTypeFilter {
  OpenAccess = 'hasOpenAccess',
  ExcessiveAccess = 'hasExcessiveAccess',
  CrossDepartmentAccess = 'hasCrossDepartmentAccess'
}
export interface AccessControlObjectsWithImpactedAccess {
  totalObjects: number
  impactedObjects: number
  datasources: {
    id?: string
    name?: string
    type?: DATA_SOURCE_TYPES
    totalObjects?: number
    impactedObjects?: number
  }[]
}

export interface AccessControlObjectsWithImpactedAccessByDept {
  totalObjects: number
  totalDepartments: number
  totalOwners: number
  departments: {
    name?: string
    totalObjects?: number
  }[]
}

export const SENSITIVITY_LEVELS = [
  DataSensitivityLevels.High,
  DataSensitivityLevels.Medium,
  DataSensitivityLevels.Low
]

export type AccessControlUserEntityCard = {
  id: number
  name: string
  entitiesCount: number
  atRiskCount: number
  associatedDatasources: EntityTypeGroupByDatasource[]
}

export type EntityTypeGroupByDatasource = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
}

export type AccessControlEntityAccessWidget = {
  totalUsers: number
  entityTypes: { id: string; name: string; entityCount: number; accessibleEntityCount: number }[]
}
// User Attributes
export type AccessControlUserAttributesWidget = {
  attributeSensitivity: DataSensitivityLevels
  count: number
}

export type AccessControlUserAttributeCard = {
  attributeId: string
  attributeName: string
  sensitivityLabel: string
  dataSourcesCount: number
  dataSources: AttributeDataSource[]
}

export type CrossDepartmentItem = {
  name: string
  totalObjects: number
  totalOwners: number
  crossDepartments: {
    name: string
    userCount: number
  }[]
}
export type AccessControlCrossDepartmentAccessWidget = {
  totalObjects: number
  totalDepartments: number
  totalOwners: number
  departments: CrossDepartmentItem[]
}

export type AccessControlAccessibleWidget = {
  usersCount: number
  groupsCount: number
  rolesCount: number
  resourcesCount: number
}

export type AccessControlFilters = {
  departments?: string[]

  entityTypes?: {
    id: string
    name: string
  }[]
  datasources?: {
    id: string
    name: string
  }[]
  attributes?: {
    id: string
    name: string
    internalName: string
  }[]
}

export enum RiskFilterTypes {
  Risky = 'At Risk',
  NotRisky = 'Not Risk'
}

export const SensitivityLevelsColors = {
  [DataSensitivityLevels.High]: '#EB6818',
  [DataSensitivityLevels.Medium]: '#F49E17',
  [DataSensitivityLevels.Low]: '#2EB67D'
}

export enum UserObjectsTabs {
  Owned = 'Owned',
  Accessible = 'Accessible',
  OpenAccess = 'OpenAccess',
  DirectAccess = 'DirectAccess',
  AccessViaGroup = 'AccessViaGroup'
}

type CronSchedule = {
  cronType: CronScheduleTypes
  humanReadableSchedule?: {
    frequencyType: string
    frequencyValue: number
  }
  cronExpression?: string
}

export type IdentityProviderParams = {
  id?: string
  name: string
  email: string
  domain?: string
  endpoint?: string
  username?: string
  password?: string
  sslEnabled?: boolean
  delegatedCredential?: string
  accountJson?: string
  entraAd?: boolean
  datasourceIds?: string[]
  cronSchedule?: CronSchedule
  type: DirectoryOptions
  hostedConfig?: HostedConfig
}

export type TestConnectionParams = {
  type: DirectoryOptions
  accountJson?: string
  delegatedCredential?: string
  domain?: string
  endpoint?: string
  username?: string
  password?: string
  sslEnabled?: boolean
  hostedConfig?: HostedConfig
}
export interface DirectoryService {
  id: string
  name: string
  idpType: string
  lastSyncedAt?: string
  datasourceCount: number
  ownerEmail?: string
  description?: string
  datasources?: DataSource[]
  cronSchedule?: CronSchedule
}

export type AlertsOverviewWidget = {
  id: string
  name: string
  status: string
  severity: string
  generatedAt: string
  assignees: number
  policyType: string
  datasourceId: string
}
export enum AccessReportFilters {
  Sensitivity = 'sensitivity',
  EmployeeType = 'employeeType',
  Department = 'departmentName',
  DataSource = 'datasourceId',
  Objects = 'objectIds',
  User = 'accessControlUserIds',
  Group = 'accessControlGroupIds',
  DatasourceIds = 'datasourceIds',
  EntityType = 'entityTypeIds',
  Attribute = 'attributeTypeIds',
  Entity = 'entityIds',
  HasEntityAccess = 'hasEntityAccess',
  IsOwner = 'isOwner'
}

export enum AccessControlAccessMode {
  Read = 'READ',
  Write = 'WRITE'
}
