import {
  mapQueryTemplateAttributes,
  mapQueryTemplates,
  mutationCreateTemplate,
  mutationDeleteTemplate,
  mutationUpdateTemplate,
  queryTemplateAttributes,
  queryTemplates
} from './queries'
import service from '../../services/api/apiService'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface Template {
  id: string
  templateName: string
  backingDocumentId: string
  enabled: boolean
  templateClass: string
  templateSubClass: string
  templateWorkflowStatus: boolean
  userKeywords?: string[]
  attributesCount?: number
  objectsCount?: number
  createdBy?: string
}

// CRUD
export const fetchTemplateById = createAsyncThunk(
  'templates/templateById',
  async (templateId: string) => await service.getTemplateById(templateId)
)

export const ACTION_TEMPLATES_LIST = 'templates/list'
export const fetchTemplates = createAsyncThunk(ACTION_TEMPLATES_LIST, async () => {
  const resultRaw = await graphqlService.execute(queryTemplates())
  return mapQueryTemplates(resultRaw)
})

export type CreateTemplateParams = {
  templateName: string
  backingDocumentId: string
  backingDatasourceId: string
  enabled: boolean
  templateClass: string
  templateSubClass: string
  userKeywords?: string[]
}
export const ACTION_TEMPLATE_CREATE = 'templates/create'
export const createTemplate = createAsyncThunk(
  ACTION_TEMPLATE_CREATE,
  async (params: CreateTemplateParams, { rejectWithValue }) => {
    const result = await graphqlService.execute(mutationCreateTemplate(params))
    if (!result?.createTemplate.status) {
      return rejectWithValue({
        ...(result?.createTemplate || {}),
        statusMessage: result?.createTemplate?.message || 'annotations.template.create.error'
      })
    }
    return result
  }
)

export type UpdateTemplateParams = {
  id: string
  templateName?: string
  backingDocumentId?: string
  backingDatasourceId?: string
  enabled?: boolean
  templateClass?: string
  templateSubClass?: string
  userKeywords?: string[]
}
export const ACTION_TEMPLATE_UPDATE = 'templates/update'
export const updateTemplate = createAsyncThunk(
  ACTION_TEMPLATE_UPDATE,
  async (params: UpdateTemplateParams) => {
    await graphqlService.execute(mutationUpdateTemplate(params))
    return params.id
  }
)

export const ACTION_TEMPLATE_DELETE = 'templates/delete'
export const deleteTemplate = createAsyncThunk(ACTION_TEMPLATE_DELETE, async (id: string) => {
  await graphqlService.execute(mutationDeleteTemplate(id))
  return id
})

export type TemplateAttribute = {
  id: string
  name: string
  internalName: string
  objectsCount: number
}
export const ACTION_TEMPLATE_ATTRIBUTES = 'templates/attributes'
export const fetchTemplateAttributes = createAsyncThunk(
  ACTION_TEMPLATE_ATTRIBUTES,
  async (templateId: string) => {
    const raw = await graphqlService.execute(queryTemplateAttributes(templateId))
    return mapQueryTemplateAttributes(raw)
  }
)

interface TemplatesState {
  templates?: Template[]
  templateAttributes?: TemplateAttribute[]
}

export const initialState: TemplatesState = {}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload
    })
    builder.addCase(fetchTemplateAttributes.fulfilled, (state, { payload }) => {
      state.templateAttributes = payload
    })
  }
})

// export const {} = templatesSlice.actions

export default templatesSlice.reducer
