import { EXCESSIVE_ACCESS_TYPES, OPEN_ACCESS_TYPES, POLICY_TYPES } from '../../../constants'
import AnyoneWithTheLinkIcon from '../../../assets/inlineSvg/icon-anyone-with-link.svg'
import ExternalPeopleIcon from '../../../assets/inlineSvg/icon-external-people.svg'
import OrganizationWithTheLinkIcon from '../../../assets/inlineSvg/icon-organisation-with-link.svg'
import { gql } from 'graphql-request'

export const STEPS = [
  ' Rule Set Criteria',
  'Data Sources',
  'Alert & Notifications',
  'Automation (Beta)'
]

export const POLICY_WITH_RULESETS = [POLICY_TYPES.ENTITY_LOCATION_POLICY]

export const getSteps = (isPolicyDocumentLabeling: boolean) =>
  isPolicyDocumentLabeling ? [...STEPS].splice(0, 2) : STEPS

export enum PolicySteps {
  rulesetCriteria = 0,
  dataSources,
  alertAndNotifications,
  automation
}

const iconMapper = {
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE]: ExternalPeopleIcon,
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE_WITH_WRITE_ACCESS]: ExternalPeopleIcon,
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK]: AnyoneWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS]: AnyoneWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK]: OrganizationWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS]: OrganizationWithTheLinkIcon
}

export const getAccessControlOpenAccessTypeOptions = (intl) => {
  return Object.keys(OPEN_ACCESS_TYPES).map((type) => ({
    key: type,
    value: type,
    label: intl.formatMessage({ id: `ruleset.field.accessSubType.${type}` }),
    description: intl.formatMessage({
      id: `ruleset.field.accessSubType.${type}.description`
    }),
    icon: iconMapper[OPEN_ACCESS_TYPES[type]]
  }))
}

export const getExcessiveAccessOpenAccessTypeOptions = (intl) => {
  return Object.keys(EXCESSIVE_ACCESS_TYPES).map((type) => ({
    key: type,
    value: EXCESSIVE_ACCESS_TYPES[type],
    label: intl.formatMessage({ id: `ruleset.field.accessSubType.${EXCESSIVE_ACCESS_TYPES[type]}` })
  }))
}

enum AccessMode {
  PublicLink = 'PUBLIC_LINK',
  OrganizationLink = 'ORGANIZATION_LINK',
  Link = 'LINK',
  Direct = 'DIRECT'
}

enum AccessAttribute {
  Department = 'DEPARTMENT',
  Company = 'COMPANY'
}

enum Operator {
  NotEq = 'NOT_EQ',
  Eq = 'EQ'
}

export enum AccessPermission {
  Read = 'READ',
  Write = 'WRITE'
}

export const AccessPolicyQueryMapper: {
  [key in OPEN_ACCESS_TYPES | EXCESSIVE_ACCESS_TYPES]: {
    accessMode: AccessMode[]
    accessPermission?: AccessPermission[]
    attribute?: AccessAttribute
    operator?: Operator
  }
} = {
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE]: {
    accessMode: [AccessMode.Direct],
    attribute: AccessAttribute.Company,
    operator: Operator.NotEq
  },
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE_WITH_WRITE_ACCESS]: {
    accessMode: [AccessMode.Direct],
    accessPermission: [AccessPermission.Write],
    attribute: AccessAttribute.Company,
    operator: Operator.NotEq
  },
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK]: {
    accessMode: [AccessMode.PublicLink]
  },
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS]: {
    accessMode: [AccessMode.PublicLink],
    accessPermission: [AccessPermission.Write]
  },

  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK]: {
    accessMode: [AccessMode.OrganizationLink]
  },
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS]: {
    accessMode: [AccessMode.OrganizationLink],
    accessPermission: [AccessPermission.Write]
  },
  [EXCESSIVE_ACCESS_TYPES.READ_WRITE]: {
    accessMode: [AccessMode.Direct, AccessMode.Link]
  },
  [EXCESSIVE_ACCESS_TYPES.WRITE_ACCESS]: {
    accessMode: [AccessMode.Direct, AccessMode.Link, AccessMode.PublicLink],
    accessPermission: [AccessPermission.Write, AccessPermission.Write, AccessPermission.Write]
  }
}

const accessValuesFragment = (values: string[], permission?: string[]) => {
  return values
    .map(
      (value, idx) => `{
  accessMode: ${value}
  ${permission?.at(idx) ? `accessPermissions: [${permission[idx]}]` : ''}
  }`
    )
    .join('')
}

export const getAccessRulesQueryAccessConditions = (
  type?: OPEN_ACCESS_TYPES | EXCESSIVE_ACCESS_TYPES,
  count?: number
) => {
  if (!type) return ``
  const isExcessiveAccess =
    [EXCESSIVE_ACCESS_TYPES.READ_WRITE, EXCESSIVE_ACCESS_TYPES.WRITE_ACCESS].includes(
      type as EXCESSIVE_ACCESS_TYPES
    ) &&
    count &&
    count > 0
  return gql`{
    operator: ALL_OF,
    conditions: [
     ${
       AccessPolicyQueryMapper?.[type]?.attribute
         ? `
       { conditionType: ACCESSOR_ATTRIBUTE_OPERATOR_OBJECT_OWNER_ATTRIBUTE,
        accessorAttributeArg:
          {
            operator: ${AccessPolicyQueryMapper[type].operator},
            attributeType: ${AccessPolicyQueryMapper[type].attribute}
          }
      }`
         : ''
     },
      {
        conditionType: ACCESS_TYPE_OPERATOR_SPECIFIC_VALUES
        accessTypeArg: {
          operator: IN
          accessTypeValues: [${accessValuesFragment(
            AccessPolicyQueryMapper[type]?.accessMode,
            AccessPolicyQueryMapper[type]?.accessPermission
          )}]
        }
      },
      ${
        isExcessiveAccess
          ? `
        {
          conditionType: ACCESSOR_AGGREGATION_OPERATOR_CONDITION
          accessorAggregationArg: {
            operator: GREATER_THAN
            aggregationOp: COUNT
            conditionValues: [${count}]
          }
        }
        `
          : ''
      }
    ]
  }`
}

export enum AlertStatus {
  active = 'Active'
}
