import {
  DataSourceRiskScore,
  RiskScoreWorkFlows,
  sensitivityRiskWeightsType
} from './riskScoreSlice'
import { AttributeNameIds } from '../attributes/attributesSlice'
import { DATA_SOURCE_TYPES } from '../../constants'
import { gql } from 'graphql-request'

export const queryRiskScoreStatus = (): string => {
  return gql`
    query risk_score_status {
      riskScoreConfig {
        isRiskScoreEnabled
      }
    }
  `
}

export const mapQueryRiskScoreStatus = (raw: any): any => {
  try {
    return raw?.riskScoreConfig?.isRiskScoreEnabled || false
  } catch (error) {
    console.error(error)
  }
}

export const queryRiskScoreConfig = (): string => {
  return gql`
    query risk_score_config {
      riskScoreConfig {
        isRiskScoreEnabled
        workflowStatus
        sensitivityRiskWeights {
          sensitivityLevel
          riskWeight
        }
      }
    }
  `
}

export const mapQueryRiskScoreConfig = (
  raw: any
): {
  isRiskScoreEnabled: boolean
  sensitivityRiskWeights: sensitivityRiskWeightsType[]
  workflowStatus: RiskScoreWorkFlows
} => {
  const isRiskScoreEnabled = raw?.riskScoreConfig?.isRiskScoreEnabled || false
  const sensitivityRiskWeights: sensitivityRiskWeightsType[] =
    raw?.riskScoreConfig?.sensitivityRiskWeights || []
  const workflowStatus = raw?.riskScoreConfig?.workflowStatus

  return { sensitivityRiskWeights, isRiskScoreEnabled, workflowStatus }
}

export function stringifySensitivityRiskWeights(
  sensitivityRiskWeights: { sensitivityLevel: string; riskWeight: number }[]
) {
  return sensitivityRiskWeights
    .map(
      ({ sensitivityLevel, riskWeight }) =>
        `{sensitivityLevel: ${sensitivityLevel}, riskWeight: ${riskWeight}}`
    )
    .join(',')
}

export function stringifyAttributeRiskWeights(
  attributeRiskWeights: { id: string; riskWeight: number }[]
) {
  return attributeRiskWeights
    .map(({ id, riskWeight }) => `{attributeId: "${id}", riskWeight: ${riskWeight}}`)
    .join(',')
}

export const mutationSaveRiskScoreConfig = ({
  isRiskScoreEnabled,
  sensitivityRiskWeights,
  attributeRiskWeights
}) => {
  return gql`
    mutation {

        updateRiskScoreConfig(
          riskScoreConfigData: {
            isRiskScoreEnabled: ${isRiskScoreEnabled}
            sensitivityRiskWeights: [${stringifySensitivityRiskWeights(sensitivityRiskWeights)}]
            attributeRiskWeights: [${stringifyAttributeRiskWeights(attributeRiskWeights)}]
          }
        ) {
          success
        }

    }
  `
}

export const queryAttributeNamesIdsForRiskScore = (): string => {
  return gql`
    {
      riskScoreConfig {
        sensitivityRiskWeights {
          sensitivityLevel
          riskWeight
        }
      }
      attribute(booleanFilter: [{ key: ENABLED, value: true }]) {
        edges {
          node {
            id
            name
            internalName
            enabled
            sensitivityLabel
            riskWeight
          }
        }
      }
    }
  `
}

export const mapQueryAttributeNameIdsForRiskScore = (raw: any): { list: AttributeNameIds[] } => {
  const sensitivityRiskWeights: sensitivityRiskWeightsType[] =
    raw?.riskScoreConfig?.sensitivityRiskWeights || []

  const list = raw?.attribute?.edges?.map(
    ({
      node: { name: attrName, internalName, id: attrId, enabled, sensitivityLabel, riskWeight }
    }) => ({
      name: attrName,
      internalName,
      id: attrId,
      enabled,
      sensitivityLabel,
      riskWeight:
        riskWeight ??
        sensitivityRiskWeights.find((s) => s.sensitivityLevel === sensitivityLabel)?.riskWeight
    })
  )

  return { list: [...new Set<AttributeNameIds>(list)] }
}

export const queryRiskScoreByDataSourceId = (dataSourceId: string) => {
  return gql`
    {
      datasources(datasourceIds: ["${dataSourceId}"]) {
        edges {
          node {
            numberOfObjects
            totalRiskScore
            riskDensity
            riskDistribution
          }
        }
      }
    }
  `
}

export const queryDataSourcesRiskScore = (): string => {
  return gql`
    {
      datasources {
        edges {
          node {
            id
            name
            type
            totalRiskScore
            riskDensity
          }
        }
      }
    }
  `
}

export const mapQueryRiskScoreByDataSourceId = (raw: any) => {
  const dataSource = raw.datasources?.edges[0]?.node
  let numberOfSensitiveObjects = 0
  const riskScoreHashTable = [0, 0, 0, 0, 0] /* Considering 5 splits */
  let riskScoreGraphData: any = []

  const getRiskScoreHashIndex = (riskScore: number) =>
    riskScore > 0 ? Math.floor((riskScore - 1) / 20) : -1 // Exclude 0 key data

  if (dataSource?.riskDistribution) {
    const riskDistributionObj = JSON.parse(dataSource?.riskDistribution)
    Object.keys(riskDistributionObj)?.forEach((riskScore: any) => {
      const riskScoreHashIndex = getRiskScoreHashIndex(riskScore)

      if (riskScoreHashIndex !== -1) {
        riskScoreHashTable[riskScoreHashIndex] += riskDistributionObj[riskScore]
        numberOfSensitiveObjects += riskDistributionObj[riskScore]
      }
    })

    riskScoreGraphData = riskScoreHashTable.map((count, index) => ({
      name: `${20 * index + 1}-${20 * (index + 1)}`,
      value: (count / numberOfSensitiveObjects) * 100,
      totalObjects: count
    }))
  }

  return {
    numberOfSensitiveObjects,
    riskDensity: dataSource?.riskDensity,
    riskScoreGraphData
  }
}

export const mapQueryDataSourcesRiskScore = (raw: any): DataSourceRiskScore[] => {
  try {
    const list: DataSourceRiskScore[] = raw?.datasources?.edges
      ?.map(({ node }) => ({
        id: node.id,
        name: node.name,
        type: node.type,
        totalRiskScore: node.totalRiskScore || 0,
        riskDensity: node.riskDensity || 0
      }))
      .filter((ds) => ds.type !== DATA_SOURCE_TYPES.generic && ds.type !== DATA_SOURCE_TYPES.remote)
    return list
  } catch (error) {
    console.error(error)
    throw error
  }
}
