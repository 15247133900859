import {
  TimestampRequestParams,
  NamedTimeSeriesItem,
  AnnotationsWidgetData,
  FileTypeWidgetData,
  UserEntityBeingShared,
  FileTypeWidget
} from '../../../services/api/apiTypes'
import { IListItemLabeled } from '../../../components/ListItemLabeled'
import { AlertListingWidgetItem, LOADING } from '../../../interfaces'
import service from '../../../services/api/apiService'
import { getMessageByApiStatus } from '../../../services/api/apiStatuses'
import { getSortDirection, defaultSortParams, SortParams } from '../../../utils/sortUtil'
import dayjs from 'dayjs'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface ITotalAlerts {
  total: number
  critical: number
  info: number
  warning: number
}

export interface ISharedEntityItem {
  entity: string
  attributes: number
  shared: string
}

export interface IAlertsWidgetData {
  categoriesData: {
    [key: string]: {
      timeseries: Array<NamedTimeSeriesItem>
      name: string
      total: number
    }
  }
  listData: Array<AlertListingWidgetItem>
}
export interface IConnectedItem {
  value: number
  name: string
}

export interface IConnectedApps {
  total?: number
  data?: {
    [key: string]: IConnectedItem
  }
}

export interface ISensitiveSharedData {
  [key: string]: {
    name: string
    data: {
      [key: string]: number
    }
  }
}

export interface FileTypeItem {
  file: string
  total: number
  holdsPIIData: number
}

interface GoogleDriveDashboardState {
  dateRange: TimestampRequestParams
  totalAlerts: ITotalAlerts
  totalUsers: number | null
  duplicateRecords: number | null
  totalFiles?: number
  alertsWidget: IAlertsWidgetData
  annotationsWidgetData: AnnotationsWidgetData | null
  alertActivityFeed: Array<IListItemLabeled>
  connectedAppsData: IConnectedApps
  fileTypeWidgetData: FileTypeWidgetData | null
  sensitiveSharedData: ISensitiveSharedData
  entitiesBeingShared: Array<UserEntityBeingShared>
  fileTypeWidgetList: Array<any>
  fileTypeSort: SortParams
  loading: LOADING
  success: string
  error: string
}

const DEFAULT_FILE_TYPE_DATA = {
  totalCount: [],
  piiCount: [],
  fileTypesCount: 0
}

export const initialState: GoogleDriveDashboardState = {
  dateRange: {
    endDate: dayjs().unix(),
    startDate: dayjs().subtract(1, 'week').unix()
  },
  totalAlerts: {
    total: 400,
    critical: 54,
    info: 21,
    warning: 25
  },
  totalUsers: null,
  duplicateRecords: null,
  alertsWidget: {
    categoriesData: {
      unresolved: {
        name: 'Unresolved',
        total: 160,
        timeseries: [
          { timestamp: 1608336000.0, value: 1100, units: null },
          { timestamp: 1608422400.0, value: 600, units: null },
          { timestamp: 1608508800.0, value: 1300, units: null },
          { timestamp: 1608595200.0, value: 450, units: null },
          { timestamp: 1608681600.0, value: 600, units: null },
          { timestamp: 1608768000.0, value: 200, units: null },
          { timestamp: 1608854400.0, value: 400, units: null }
        ]
      },
      resolved: {
        name: 'Resolved',
        total: 240,
        timeseries: [
          { timestamp: 1608336000.0, value: 2200, units: null },
          { timestamp: 1608422400.0, value: 2800, units: null },
          { timestamp: 1608508800.0, value: 2800, units: null },
          { timestamp: 1608595200.0, value: 2400, units: null },
          { timestamp: 1608681600.0, value: 2700, units: null },
          { timestamp: 1608768000.0, value: 100, units: null },
          { timestamp: 1608854400.0, value: 400, units: null }
        ]
      }
    },
    listData: [
      {
        timestamp: 1608736450,
        newCount: 8,
        list: [
          {
            type: 'error',
            message: 'John Doe is sharing sensitive data outside the organization.',
            isNew: true
          },
          {
            type: 'error',
            message: 'Sensitive data getting exchange with Sample 2',
            isNew: true
          },
          {
            type: 'warn',
            message: 'John Doe is sharing sensitive data outside the organization.',
            isNew: true
          },
          {
            type: 'warn',
            message: 'Sensitive data getting exchange with Sample 2'
          },
          {
            type: 'warn',
            message: 'John Doe is sharing sensitive data outside the organization.'
          },
          {
            type: 'warn',
            message: 'Sensitive data getting exchange with Sample 2'
          }
        ]
      }
    ]
  },
  annotationsWidgetData: null,
  alertActivityFeed: [
    {
      info: 'John Doe is assigned to configure Dennis Ball’s PDF files.',
      status: 'in progress'
    },
    {
      info: 'John Doe is assigned to configure Dennis Ball’s PDF files.',
      status: 'open'
    },
    {
      info: 'John Doe is assigned to configure Dennis Ball’s PDF files.',
      status: 'closed'
    },
    {
      info: 'John Doe is assigned to configure Dennis Ball’s PDF files.',
      status: 'in progress'
    },
    {
      info: 'John Doe is assigned to configure Dennis Ball’s PDF files.',
      status: 'open'
    }
  ],
  connectedAppsData: {
    total: 15,
    data: {
      notSharing: {
        value: 7,
        name: 'Not Sharing Data'
      },
      sharing: {
        value: 8,
        name: 'Sharing PII Data'
      }
    }
  },
  fileTypeWidgetData: DEFAULT_FILE_TYPE_DATA,
  fileTypeWidgetList: [],
  sensitiveSharedData: {
    internal: {
      name: 'Internal',
      data: {
        files: 4000,
        folders: 2400
      }
    },
    external: {
      name: 'External',
      data: {
        files: 3000,
        folders: 1398
      }
    }
  },
  entitiesBeingShared: [],
  fileTypeSort: defaultSortParams,
  loading: LOADING.idle,
  success: '',
  error: ''
}

export const getAnnotationsWidgetDataById = createAsyncThunk(
  'google-dashboard/annotations-widget',
  async (datasourceId: string) => {
    try {
      return await service.getAnnotationsWidgetDataById(datasourceId)
    } catch (e) {
      throw new Error(e as string)
    }
  }
)

export const fetchTotalFilesCount = createAsyncThunk(
  'googleDriveDashboard/totalFilesCount',
  async (dataSourceId: string) => await service.getTotalFilesCount(dataSourceId)
)

export const fetchDuplicateRecordsCount = createAsyncThunk(
  'googleDriveDashboard/duplicateRecordsCount',
  async () =>
    await service.getDuplicateRecordsCount().catch((e) => {
      throw new Error(e)
    })
)

export const getFileTypeWidgetData = createAsyncThunk(
  'googleDriveDashboard/fileTypesWidget',
  async (dataSourceId: string) => await service.getFileTypeWidgetData(dataSourceId)
)

const googleDriveDashboardSlice = createSlice({
  name: 'googleDriveDashboard',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setSortForFileTypeWidget: (state, action) => {
      state.fileTypeSort = getSortDirection(state.fileTypeSort, action.payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnotationsWidgetDataById.fulfilled, (state, action) => {
      state.annotationsWidgetData = action.payload
    })
    builder.addCase(getAnnotationsWidgetDataById.rejected, (state) => {
      state.annotationsWidgetData = {}
    })
    builder.addCase(fetchTotalFilesCount.pending, (state) => {
      state.loading = LOADING.pending
      state.success = ''
      state.error = ''
    })
    builder.addCase(fetchTotalFilesCount.rejected, (state, action) => {
      state.loading = LOADING.idle
      state.error = getMessageByApiStatus(action.error?.message)
    })
    builder.addCase(fetchTotalFilesCount.fulfilled, (state, action) => {
      state.loading = LOADING.idle
      state.totalFiles = action.payload.total
    })
    builder.addCase(fetchDuplicateRecordsCount.fulfilled, (state, action) => {
      state.duplicateRecords = action.payload.count
    })
    builder.addCase(fetchDuplicateRecordsCount.rejected, (state) => {
      state.duplicateRecords = 0
    })
    builder.addCase(getFileTypeWidgetData.pending, (state) => {
      state.loading = LOADING.pending
      state.success = ''
      state.error = ''
    })
    builder.addCase(getFileTypeWidgetData.fulfilled, (state, action) => {
      state.loading = LOADING.idle
      const fileTypeApiData = action.payload

      const fileTypeData: Array<FileTypeItem> = []

      fileTypeApiData.totalCount.forEach((totalItem) => {
        const PIItem = fileTypeApiData.piiCount.find(
          (item: FileTypeWidget) => item.name === totalItem.name
        )

        PIItem &&
          fileTypeData.push({
            file: totalItem.name,
            total: totalItem.value,
            holdsPIIData: PIItem.value
          })
      })

      state.fileTypeWidgetList = fileTypeData
    })
    builder.addCase(getFileTypeWidgetData.rejected, (state, action) => {
      state.loading = LOADING.idle
      state.error = getMessageByApiStatus(action.error?.message)
    })
  }
})

export const { setDateRange, setSortForFileTypeWidget } = googleDriveDashboardSlice.actions

export default googleDriveDashboardSlice.reducer
